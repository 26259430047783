export const paths = {
  AUDIENCES: '/audiences',
  AUDIENCES_ATTAIN_TAB: '/attain',
  AUDIENCES_MY_AUDIENCE_TAB: '/my-audiences',
  AUDIENCES_BUILD: '/audiences/build',
  AUDIENCES_BUILD_EXISTING: '/audiences/build/:id',
  AUDIENCE_DETAILS: '/details',
  ACTIVITY: '/activity',
  ACTIVITY_MAP: '/activity-map',
  HOME: '/home',
  INSIGHTS: '/insights',
  INSIGHTS_BRANDS:
    '/insights-brands/:paramSegmentID/:timePeriod/:customTimePeriodEnd?',
  INSIGHTS_MERCHANTS:
    '/insights-merchants/:paramSegmentID/:timePeriod/:customTimePeriodEnd?',
  INSIGHTS_AFFINITIES:
    '/insights/affinities/:audienceId/:timePeriod/:customTimePeriodEnd?',
  INSIGHTS_CONQUESTING:
    '/insights/conquesting/:merchantId/:timePeriod/:customTimePeriodEnd?',
  INSIGHTS_CONSUMER_PROFILE:
    '/insights/consumer-profile/:firstAudienceId/:secondAudienceId/:timePeriod/:customTimePeriodEnd?',
  INSIGHTS_CROSS_PURCHASE:
    '/insights/cross-purchase/:firstMerchantId/:secondMerchantId/:thirdMerchantId/:timePeriod/:customTimePeriodEnd?',
  INSIGHTS_RFM: '/insights/rfm/:merchantId/:timePeriod/:customTimePeriodEnd?',
  INSIGHTS_SWITCHING: '/insights/switching/:merchantId/:prePeriod/:postPeriod/',
  LOGIN: '/login',
  MAP: '/activity-map',
  MAPS: '/maps',
  MEASUREMENT: '/measurement',
  MEASUREMENT_DASHBOARD: '/measurement/dashboard',
  MEASUREMENT_DASHBOARD_INCREMENTALITY: '/incrementality',
  MEASUREMENT_DASHBOARD_OVERVIEW: '/overview',
  MEASUREMENT_DASHBOARD_CAMPAIGN_INSIGHTS: '/campaign-insights',
  MEASUREMENT_DASHBOARD_OPTIMIZATION: '/optimization',
  MEASUREMENT_DASHBOARD_NEW_BUYER_LIFT: '/new-buyer-lift',
  MEASUREMENT_DASHBOARD_SUMMARY: '/summary',
  MEASUREMENT_DASHBOARD_BRAND_SURVEY: '/brand-survey',
  AMT: '/amt',
  AMT_CAMPAIGNS: '/amt/campaigns',
  AMT_CAMPAIGN: '/amt/campaigns/campaign/:id?',
  AMT_DATAFEEDS: '/amt/datafeeds',
  AMT_DATAFEED: '/amt/datafeeds/datafeed/:id?',
  AMT_PERMISSIONS: '/amt/permissions',
  AMT_PERMISSIONS_PEOPLE: '/people',
  AMT_PERMISSIONS_TEAMS: '/teams',
  ONBOARDING: '/onboarding/:step?',
  SIGNUP: '/signup',
  SIGNUP_V1: '/signup-v1',
  SIGNUP_V2: '/signup-v2',
  SIGNUP_V3: '/signup-v3',
  SIGNUP_MARKET_RESEARCH: '/signup-market-research',
  SIGNUP_MARKET_SHARE: '/signup-market-share',
  SIGNUP_CONQUESTING: '/signup-conquesting',
  SIGNUP_COMPETITOR: '/signup-competition',
  SIGNUP_DEMOGRAPHICS: '/signup-demographics',
  SIGNUP_DIRECT: '/signupdirect',
  SMART_SIGNALS: '/smart-signals',
  SMART_SIGNALS_RESULT: '/smart-signals/:merchantId',
  LOGIN_REDIRECT: '/loginredirect',
  VERIFYEMAIL: '/verifyemail',
  RESEND_EMAIL_VERIFICATION: '/resendEmailVerification',
};

export default paths;
